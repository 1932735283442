import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Banner from '../components/Banner'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { BlogPostJsonLd } from 'gatsby-plugin-next-seo'
import Seo from '../components/SEO/PageSeo'

const PostTemplate = ({ data }) => {
  const {
    mdx: {
      frontmatter: { title, category, image, date, slug, author, modified },
      body,
      excerpt,
    },
  } = data

  // Convert Date for Display

  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  const dateLang = new Date(date).toLocaleString('fr-FR', dateOptions)

  // Convert Date for JSON-LD
  const d = new Date(date)
  let jsonDate = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()

  return (
    <Layout>
      <Wrapper>
        <Seo
          title={title}
          description={excerpt}
          image={image.childImageSharp.gatsbyImageData.images.fallback.src}
        />
        <BlogPostJsonLd
          url={'https://construire-avec-mca-france.netlify.app/posts/' + slug}
          title={title}
          images={image.childImageSharp.gatsbyImageData.images.fallback.src}
          datePublished={jsonDate}
          dateModified={modified}
          authorName={author}
          publisherName="Jay Fischer"
          publisherLogo="https://www.inkandfeather.de/ink-logo-white.webp"
          description={excerpt}
        />

        <article>
          <GatsbyImage
            image={getImage(image)}
            alt={title}
            className="main-img"
          />

          <div className="post-info">
            <span>{category}</span>
            <h1>{title}</h1>
            <p>{dateLang}</p>
            <div className="underline"></div>
          </div>
          <MDXRenderer>{body}</MDXRenderer>
        </article>
        {/*post info*/}
        {/*banner*/}
        <article>
          <Banner />
        </article>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug }, language: { eq: "fr" } }) {
      frontmatter {
        category
        date
        slug
        title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      excerpt
      body
    }
  }
`

const Wrapper = styled.section`
  width: 85vw;
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 4rem;

  .post-info {
    margin: 2rem 0 4rem 0;
    text-align: center;
    span {
      background: var(--clr-primary-5);
      color: var(--clr-white);
      border-radius: var(--radius);
      padding: 0.25rem 0.5rem;
      text-transform: uppercase;
      letter-spacing: var(--spacing);
    }
    h1 {
      margin: 1.25rem 0;
      font-weight: 400;
    }
    p {
      color: var(--clr-grey-5);
    }
  }
  @media (min-width: 992px) {
    & {
      width: 92vw;
    }
    .main-img {
      width: 75%;
      display: block;
      margin: 0 auto;
    }
  }
  @media (min-width: 1170px) {
    & {
      display: grid;
      grid-template-columns: 1fr 200px;
      column-gap: 4rem;
    }
  }
`

export default PostTemplate
